body {
  background: #f3f4f6;
  font-family: "Inter", sans-serif;
}

.App {
  padding-bottom: 50px;
  font-family: "Inter", sans-serif;
}

h1 {
  font-family: "Inter", sans-serif;
}

.color_primary {
  color: #0050aa !important;
}

.primary_bg {
  background-color: #0050aa !important;
}

/* Pagination css */

.pagination_product {
  display: flex;
  justify-content: center;
  margin-top: 46px;
}

.limit_select-md {
  width: 72px;
  height: 38px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.limit_select-lg {
  width: 72px;
  height: 56px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.page-link {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px;
}

.active>.page-link,
.page-link.active {
  background-color: #0050aa !important;
  border-color: var(--bs-pagination-active-border-color);
  color: #fff !important;
  z-index: 3;
}


.pagination_product .form-select:focus {
  box-shadow: none !important;
}

.pagination_product ul li a {
  font-size: 1.25rem;
}