.login_form {
  width: 450px;
  margin: 0 auto;
  margin-top: 75px;
}

.login_form label {
  color: #353b42;
  font-size: 14px;
}

.login_form label.form-label {
  color: #353b42;
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 20px;
}

.login_form input {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  border: 1px solid #d1d5db;
  border-radius: 0px 0px 6px 6px;
}

.form-control::placeholder {
  color: #353b428f;
}

.login_form input.email_filed {
  border-radius: 6px 6px 0px 0px;
  border-bottom: none;
}

.login_form a {
  color: #0050aa;
  font-size: 14px;
  text-decoration: none;
}

.login_form button {
  background: #0050aa;
  height: 38px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.lgn_btn {
  width: 100%;
}

.label_profile_picture.form-label {
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}
