.body_title h1 {
  font-weight: 800;
  color: #353b42;
  font-size: 50px;
  text-align: center;
  margin-top: 91px;
}

.body_title h1 span {
  color: #0050aa;
}