.randerjob_new_area {
  background: #fff;
  padding: 30px 35px;
  border-radius: 8px;
}

.single_acc_item.accordion-item {
  width: 380px;
  margin-bottom: 15px;
}
.group_title {
  margin-left: -12px;
}
.randerjob_new_area .form-control::placeholder {
  font-style: italic;
}
.cancleBtn {
  background: rgb(215, 64, 58);
  padding: 6px 44px;
  margin-top: 17px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px;
}
.buttonArea {
  display: flex;
  align-items: center;
}

.renderJobButton {
  margin-right: 15px;
}
.nextButton {
  background-color: #0d6efd;
  margin-right: 15px;
}
.accroding_grid_preset {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}
.form-check.form-switch {
  width: auto;
  display: inline-block;
}
.camera_title {
  width: auto;
  margin-top: 2px;
}
#custom-switch {
  width: 40px;
  height: 18px;
}
