.primary_bg.sort_button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sort_button:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn,
.btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: none !important;
}

.scannerId,
.updatedAt,
.createdAt,
.jobUploadStatus,
.comment,
.variantId,
.productId,
.jobName,
.clientId {
  background: #0050aa !important;
  color: #fff;
  border-radius: 5px;
}

.scanJob_table {
  position: relative;
}

.scanJob_filter_loader {
  position: absolute;
  background: #353b42d4;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  top: 0;
  color: #fff;
}

.scanJob_table.table-bordered tr td {
  font-size: 12px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 12px !important;
}

.scanJob_table.table-bordered tr th {
  font-size: 11px;
}

.scanjob_btn {
  padding: 3px 15px;
  border-radius: 4px;
  background: #333;
  color: #fff !important;
  display: block;
  width: 40px !important;
}
.containercustom {
  width: 85%;
  margin: 0 auto;
}
.scanjob_btn.dual_btn {
  margin-top: 4px;
}

.scanjob_btn i {
  font-size: 12px;
}

.scanjob_btn:hover {
  background: black !important;
  color: #fff;
}

.randerjob_area {
  position: relative;
  padding-top: 30px;
}

.home_page_item_right {
  align-items: center;
}

.home_page_item_right i {
  color: #6b7280;
  margin-right: 20px;
  font-size: 12px;
}

.home_page_item_right .input-group {
  border: 1px solid #d1d5db;
  width: 320px;
  height: 38px;
  border-radius: 5px;
}

.home_page_item_right .input-group .form-control {
  border: none;
  color: #6b7280 !important;
  font-size: 14px;
}

.home_page_item_right .input-group input.form-control::placeholder {
  color: #6b7280 !important;
}

.home_page_item_right {
  align-items: center;
}
.fa.fa-times-circle:hover {
  color: red;
}
.fa.fa-times-circle {
  cursor: pointer;
  font-size: 16px;
  margin-right: 16px;
}
