.title_nf {
  font-size: 120px;
  letter-spacing: -5px;
  text-align: center;
}

.back_to_btn {
  width: 268px;
  margin: 0 auto;
  color: #000 !important;
  padding: 20px 3px;
  text-transform: uppercase;
  border-radius: 0;
  margin-top: 20px;
  border: 1px solid #000;
}

.back_to_btn:hover {
  color: #fff !important;
}
