.home_page_item_area {
  margin-top: 59px;
}

.add_ian_button {
  background: #0050aa;
  border: none;
  color: #fff;
  width: 127px;
  height: 42px;
  border-radius: 5px;
  font-weight: 500;
}

.upload_history_btn {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #0050aa;
  border: none;
  color: #0050aa;
  width: 150px;
  height: 42px;
  border-radius: 5px;
  font-weight: 500;
  margin-left: 10px;
}

.add_ian_button i {
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}

.home_page_item_right {
  align-items: center;
}

.home_page_item_right i {
  color: #353b42;
  margin-right: 20px;
  font-size: 18px;
}

.home_page_item_right .input-group {
  border: 1px solid #d1d5db;
  width: 320px;
  height: 38px;
  border-radius: 5px;
}

.home_page_item_right .input-group .form-control {
  border: none;
  color: #6b7280 !important;
  font-size: 14px;
}

.input-group-text {
  background: #fff;
  border: none;
  padding-right: 0;
}

.input-group-text i {
  color: #9ca3af;
  font-size: 16px;
  margin-right: 0;
}

.home_page_item_right .input-group input.form-control::placeholder {
  color: #6b7280 !important;
}

.form-control:focus {
  box-shadow: none;
}

.popover-body {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0px;
  width: 90px;
  display: flex;
}

.popover-header {
  font-size: 14px !important;
}

.no_round {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 1px solid #c6c6c6e8;
}

.active_sort {
  background: #d9d9d95e;
  font-weight: 700;
  padding: 2px 9px;
  width: 100%;
  padding-left: 10px;
}

.sort_item {
  padding-left: 10px;
}

.body_prop_area.popover-body {
  width: 100% !important;
  padding: 5px 3px;
}
